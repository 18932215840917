import "@hotwired/turbo-rails"
import "alpinejs"
import "flowbite/dist/flowbite.turbo.js";
import "@alpinejs/intersect"

import "./controllers"

console.log("Hello from app/javascript/application.js")

import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'

Alpine.plugin(intersect)

window.Alpine = Alpine

Alpine.start()
